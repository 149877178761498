<template>
  <div class="admin-container" v-if="data">
    <v-row>
      <v-col cols="12" md="12">
        <v-btn tile small @click="$router.push({ name: 'Projecting' })"
          >Zpět</v-btn
        >
      </v-col>
    </v-row>
    <v-row class="justify-space-between">
      <v-col cols="12" md="3">
        <h1 class="col-secondary-1">{{ data.name }}</h1>
      </v-col>
      <v-spacer></v-spacer>
      <v-col align-self="center">
        <v-row>
          <v-col
            align-self="center"
            class="pa-0 flex-1 mt-8 mt-md-0 d-flex justify-end flex-wrap flex-md-nowrap"
          >
            <v-btn
              x-small
              height="38"
              color="primary"
              tile
              class="mr-md-2 mobile-width-send-message mb-4 mb-md-0"
              @click="toMessages"
            >
              <div class="d-flex flex-align-center">
                <img
                  :src="require('@/assets/poslat_zpravu.svg')"
                  class="icon-wrapp mr-2"
                  style="width: 13px; height: 13px"
                />
                Poslat zprávu
              </div>
            </v-btn>

            <input
              type="file"
              ref="bulkUploadDocuments"
              style="display: none"
              multiple
              @input="handleBulkUploadDocuments($event.target.files)"
            />

            <v-select
              v-if="user.role != 'END_USER' && user.role != 'COMPANY_CUSTOMER'"
              class="select-projectings mr-md-2 mb-4 mt-mb-0 mobile-width-send-message"
              label="Vložit"
              :items="documentTypes"
              item-text="name"
              item-value="value"
              single-line
              dense
              solo
              height="38"
              @input="handleDocumentInput($event)"
              :full-width="false"
            ></v-select>
            <v-btn
              v-if="user.role != 'END_USER' && user.role != 'COMPANY_CUSTOMER'"
              x-small
              tile
              color="secondary"
              height="38"
              @click="
                $router.push({
                  name: 'ProjectingSubdirectory-new',
                  params: {
                    id: $route.params.id,
                  },
                })
              "
              class="mr-md-2 mb-4 mt-mb-0 mobile-width-send-message"
              ><v-icon small color="primary" class="mr-2">mdi-plus</v-icon> Nová
              složka</v-btn
            >
            <v-btn
              x-small
              height="38"
              class="mobile-width-send-message"
              tile
              @click="download"
              >Stáhnout vše</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row
      class="mt-8"
      v-if="['SUPERUSER', 'PROJECT_MANAGER'].includes(user.role)"
    >
      <v-col cols="4" md="3">
        <v-text-field v-model="data.name" label="Název složky"></v-text-field>
      </v-col>
      <v-col align-self="center">
        <v-btn small color="secondary" tile @click="updateProjecting"
          >Uložit</v-btn
        >
      </v-col>
    </v-row>

    <v-row class="mt-10">
      <v-col cols="12" md="4" v-for="it in data.subdirectories" :key="it.id">
        <v-card
          class="pa-6"
          :to="{
            name: 'ProjectingSubdirectory-detail',
            params: { id: $route.params.id, subId: it.id },
          }"
        >
          <v-row>
            <v-col class="flex-grow-0">
              <div
                class="projecting-directory projecting-directory--no-border type-icon"
                :style="iconFolder"
              ></div>
            </v-col>
            <v-col>
              <div class="projecting-card-name">{{ it.name }}</div>
              <div class="projecting-card-date mt-2">{{ it.created_at }}</div>
              <div v-if="it.editor" class="projecting-card-editor mt-2">
                {{ it.editor.first_name }} {{ it.editor.last_name }}
              </div>
              <div class="d-flex align-center projecting-card-download">
                <v-btn text small @click.prevent="downloadDirectory(it.id)">
                  <img
                    :src="require('@/assets/stahnout.svg')"
                    class="icon-wrapp mr-2"
                    style="width: 15px; height: 15px"
                  />
                  <span style="font-size: 12px">Stáhnout</span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" v-for="it in data.documents" :key="it.id">
        <v-card
          v-if="!['SUPERUSER', 'PROJECT_MANAGER'].includes(user.role)"
          class="pa-6"
        >
          <v-row class="cursor-pointer">
            <v-col class="flex-grow-0">
              <div class="projecting-directory">Dokument</div>
            </v-col>
            <v-col>
              <div class="projecting-card-name">{{ it.name }}</div>
              <div class="projecting-card-date mt-2">{{ it.created_at }}</div>
              <div v-if="it.editor" class="projecting-card-editor mt-2">
                {{ it.editor.full_name }}
              </div>
              <div class="d-flex align-center projecting-card-download">
                <v-btn text small @click.stop="downloadSingle(it.document_id)">
                  <img
                    :src="require('@/assets/stahnout.svg')"
                    class="icon-wrapp mr-2"
                    style="width: 15px; height: 15px"
                  />
                  <span style="font-size: 12px">Stáhnout</span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-card
          v-if="['SUPERUSER', 'PROJECT_MANAGER'].includes(user.role)"
          class="pa-6"
          @click="
            $router.push({
              name: 'Projecting-document-detail',
              params: { id: $route.params.id, documentId: it.id },
            })
          "
        >
          <!-- :to="{
            name: 'Projecting-document-detail',
            params: { id: $route.params.id, documentId: it.id },
          }" -->
          <v-row>
            <div
              style="
                display: flex;
                align-items: center;
                position: absolute;
                right: 0;
                top: -2px;
              "
            >
              <v-checkbox
                @click.stop=""
                hide-details
                class="small-checkbox mt-0 pt-0"
                style="margin-right: -6px"
                v-model="selected"
                :value="it.id"
              ></v-checkbox>
              <v-btn @click.stop="openDeleteSingleDialog(it.id)" small text
                ><v-icon size="18">mdi-close</v-icon></v-btn
              >
            </div>
            <v-col class="flex-grow-0">
              <div
                class="projecting-directory type-icon"
                :style="correctIcon(it)"
              ></div>
            </v-col>
            <v-col>
              <div class="projecting-card-name">{{ it.name }}</div>
              <div class="projecting-card-date mt-2">{{ it.created_at }}</div>
              <div v-if="it.editor" class="projecting-card-editor mt-2">
                {{ it.editor.full_name }}
              </div>
              <div class="d-flex align-center projecting-card-download">
                <v-btn
                  text
                  small
                  @click.prevent="downloadSingle(it.document_id)"
                >
                  <img
                    :src="require('@/assets/stahnout.svg')"
                    class="icon-wrapp mr-2"
                    style="width: 15px; height: 15px"
                  />
                  <span style="font-size: 12px">Stáhnout</span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-dialog
        v-model="deleteDialog"
        :max-width="450"
        persistent
        class="rounded-0"
      >
        <v-card>
          <v-card-title class="headline">Odstranit dokumenty</v-card-title>
          <v-card-subtitle
            >Opravdu si přejete odstranit vybrané dokumenty ?</v-card-subtitle
          >
          <v-card-text>
            <v-row> </v-row>
            <v-row class="mt-6">
              <v-col class="text-center">
                <v-btn
                  color="error"
                  small
                  tile
                  width="100%"
                  @click="handleDeleteDocuments"
                  >Odstranit</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center">
                <v-btn small text @click="deleteDialog = false" width="100%"
                  >Zrušit</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions> </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog
        v-model="deleteDialogSingle"
        :max-width="450"
        persistent
        class="rounded-0"
      >
        <v-card>
          <v-card-title class="headline">Odstranit dokument</v-card-title>
          <v-card-subtitle
            >Opravdu si přejete odstranit dokument ?</v-card-subtitle
          >
          <v-card-text>
            <v-row> </v-row>
            <v-row class="mt-6">
              <v-col class="text-center">
                <v-btn
                  color="error"
                  small
                  tile
                  width="100%"
                  @click="handleDeletedocument([focusedDocumentId])"
                  >Odstranit</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center">
                <v-btn small text @click="closeDeleteSingleDialog" width="100%"
                  >Zrušit</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions> </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row v-if="['SUPERUSER', 'PROJECT_MANAGER'].includes(user.role)">
      <v-col class="text-right">
        <v-btn
          :disabled="selected.length ? false : true"
          tile
          small
          color="error"
          @click="deleteDialog = true"
          >Odstranit</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import authHandler from '../api/authHandler';
import permissionDeniedHandler from '../api/permissionDeniedHandler';
import bus from '../plugins/bus';
import projectingService from '../api/projectingService';
import documentService from '../api/documentService';
import downloadService from '../api/downloadService';
import errorHandler from '../api/errorHandler';
import projectingDocumentService from '../api/projectingDocumentService';

const IMAGES_EXT = ['jpg', 'jpeg', 'svg', 'png'];
const PDF = 'pdf';
const ZIP = 'zip';
const PPT = 'ppt';
const XLS = 'xls';

export default {
  data() {
    return {
      selected: [],
      data: null,
      deleteDialog: false,
      deleteDialogSingle: false,
      focusedDocumentId: null,
      documentTypes: [
        { name: 'Vložit dokument', value: 'DOCUMENT' },
        { name: 'Vložit fotografii', value: 'PHOTO' },
        { name: 'Pořídit a vložit fotografii', value: 'TAKE_PHOTO' },
        { name: 'Vložit hromadně', value: 'DOCUMENTS' },
      ],
      status: [
        {
          val: 'PROJECTING',
          name: 'Projektování',
        },
      ],
    };
  },
  async created() {
    try {
      if (this.getSelectedBuilding() != null) {
        await this.show();
      } else {
        this.$router.push({ name: 'Dashboard' });
      }
    } catch (error) {
      if (!authHandler(error)) this.$router.push({ name: 'Login' });
      permissionDeniedHandler(error, this.$router);
    }
  },
  methods: {
    correctIcon(doc) {
      console.debug('documet', doc, IMAGES_EXT);
      let icon = 'document.svg';
      if (IMAGES_EXT.includes(doc.extension)) icon = 'jpg.svg';
      if (PDF == doc.extension) icon = 'pdf.svg';
      if (doc.extension.includes(ZIP)) icon = 'zip.svg';
      if (doc.extension.includes(PPT)) icon = 'ppt.svg';
      if (doc.extension.includes(XLS)) icon = 'xls.svg';
      return {
        backgroundImage: `url(${require('@/assets/' + icon)})`,
      };
    },
    closeDeleteSingleDialog() {
      this.focusedDocumentId = null;
      this.deleteDialogSingle = false;
    },
    openDeleteSingleDialog(id) {
      this.focusedDocumentId = id;
      this.deleteDialogSingle = true;
    },
    async handleBulkUploadDocuments(files) {
      try {
        bus.$emit('processing');
        console.debug('files', files);
        for (let fidx = 0; fidx < files.length; fidx++) {
          const { data } = await documentService.store(files[fidx]);
          let projectingDocument = {
            name: files[fidx].name,
            type: 'PARENT',
            document_id: data.id,
            target_id: this.$route.params.id,
          };
          await projectingDocumentService.store(
            projectingDocument,
            this.$route.params.id
          );
          console.log(files[fidx].name);
        }
        this.$refs.bulkUploadDocuments.values = [];
        bus.$emit('snackbar', {
          text: `Podařilo se nahrát dokumenty`,
          color: 'info',
        });
        bus.$emit('processing', false);
        await this.show();
      } catch (error) {
        bus.$emit('processing', false);
        errorHandler(error);
      }
    },
    async handleDeletedocument(ids) {
      try {
        bus.$emit('processing');

        await projectingDocumentService.bulkRemoveProjectigDocuments(
          this.$route.params.id,
          ids
        );

        let filteredDocuments = this.data.documents;
        ids.forEach((id) => {
          filteredDocuments = filteredDocuments.filter((doc) => doc.id != id);
        });
        this.data.documents = filteredDocuments;
        this.deleteDialog = false;
        this.deleteDialogSingle = false;
        this.focusedDocumentId = null;
        console.debug(ids);
        bus.$emit('processing', false);
      } catch (error) {
        console.debug(error);
        bus.$emit('processing', false);
        errorHandler(error);
      }
    },
    async handleDeleteDocuments() {
      try {
        bus.$emit('processing');
        await this.handleDeletedocument(this.selected);
        this.selected = [];
        this.deleteDialog = false;
        this.focusedDocumentId = null;
        this.deleteDialogSingle = false;
        bus.$emit('processing', false);
      } catch (error) {
        bus.$emit('processing', false);
        errorHandler(error);
      }
    },
    handleSelectDocument(docId) {},
    async updateProjecting() {
      try {
        bus.$emit('processing');
        await projectingService.updateProjecting(
          this.$route.params.id,
          this.data
        );
        bus.$emit('processing', false);
      } catch (error) {
        bus.$emit('processing', false);
        errorHandler(error);
        console.error(error);
      }
    },
    async downloadDoc(id) {
      try {
        bus.$emit('processing');
        await documentService.show(id);
        bus.$emit('processing', false);
      } catch (error) {
        bus.$emit('processing', false);
        errorHandler(error);
      }
    },
    async downloadSingle(id) {
      try {
        const { data } = await downloadService.single(
          'PARENT',
          id,
          this.$route.params.id
        );
        await downloadService.downloadFromCode(data.code);
      } catch (error) {
        console.error(error);
        errorHandler(error);
      }
    },
    async downloadDirectory(id) {
      try {
        bus.$emit('processing');
        const { data } = await downloadService.index(
          'CHILD',
          this.$route.params.id,
          id
        );
        await downloadService.downloadFromCode(data.code);
        bus.$emit('processing', false);
      } catch (error) {
        bus.$emit('processing', false);
      }
    },
    async download() {
      try {
        bus.$emit('processing');
        const { data } = await downloadService.index(
          'PARENT',
          this.$route.params.id
        );
        await downloadService.downloadFromCode(data.code);
        bus.$emit('processing', false);
        console.debug(data);
      } catch (error) {
        bus.$emit('processing', false);
        console.error(error);
        errorHandler(error);
      }
    },
    handleDocumentInput(e) {
      console.log('evt', e);
      if (e != 'DOCUMENTS') {
        this.$router.push({
          name: 'Projecting-document-new',
          params: {
            id: this.$route.params.id,
          },
          query: {
            documentType: e,
          },
        });
      } else {
        this.$refs.bulkUploadDocuments.click();
      }
      return;
    },
    toMessages() {
      if (['END_USER', 'COMPANY_CUSTOMER'].includes(this.user.role)) {
        this.$router.push({
          name: 'MessagesDetail',
          params: { id: this.getSelectedBuilding().project_manager.id },
          query: {
            projecting: this.$route.params.id,
          },
        });
      }
      if (['PROJECT_MANAGER', 'SUPERUSER'].includes(this.user.role)) {
        this.$router.push({
          name: 'MessagesDetail',
          params: { id: this.getSelectedBuilding().investor.id },
          query: {
            projecting: this.$route.params.id,
          },
        });
      }
    },
    getSelectedBuilding() {
      const building = JSON.parse(localStorage.getItem('building')) || null;
      return building;
    },
    async show() {
      try {
        bus.$emit('processing');
        const data = await projectingService.show(this.$route.params.id);
        this.data = data;
        console.debug('show', data);
        bus.$emit('processing', false);
      } catch (error) {
        console.error(error);
        bus.$emit('processing', false);
        console.error(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error.message
        ) {
          bus.$emit('snackbar', {
            text: error.response.data.error.message,
            color: 'error',
          });
        }
      }
    },
    async store() {
      try {
        bus.$emit('processing');
        await projectingService.store({
          ...this.data,
        });
        bus.$emit('processing', false);
        this.$router.push({ name: 'Projecting' });
      } catch (error) {
        bus.$emit('processing', false);
        console.error(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error.message
        ) {
          bus.$emit('snackbar', {
            text: error.response.data.error.message,
            color: 'error',
          });
        }
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    iconFolder() {
      return { backgroundImage: `url(${require('@/assets/folder.svg')})` };
    },
  },
};
</script>

<style></style>
